export function useAvailability() {

  const utils = useUtils();
  const appConfig = useAppConfig();

  const getVariantDeliveryInfo = (wareDeliveryText: String, variantDeliveryText: String, storedOnOwnStore: Boolean, availableCount: number, deliveryPeriod: number, nextDelivery: string, deadlineHour: number, freeDays: Array<Date>, serviceProcessingDays: Number | undefined, shippingDays: Number | undefined) => {

    let result;

    const dispatchDate = getVariantDispatchInfo(wareDeliveryText, variantDeliveryText, storedOnOwnStore, availableCount, deliveryPeriod, nextDelivery, deadlineHour, freeDays, serviceProcessingDays);
    const deliveryDate = getDeliveryDate(dispatchDate.date, freeDays, shippingDays);
    const day = deliveryDate.getDate();
    const month = deliveryDate.getMonth() + 1;

    result = { text: 'u Vás {0}'.replace('{0}', `${day}.${month}.`), deliveryDate: deliveryDate, class: 'in-stock' };

    /*
    else {
      result = { text: 'Není skladem, dostupnost neznámá', deliveryDate: null, class: 'not-stored' };
    }*/

    return result;
  }

  const getVariantDispatchInfo = (wareDeliveryText: String, variantDeliveryText: String, storedOnOwnStore: Boolean, availableCount: number, deliveryPeriod: number, nextDelivery: string, deadlineHour: number, freeDays: Array<Date>, serviceProcessingDays: Number | undefined) => {

    let result = {};

    const formatter = new Intl.DateTimeFormat('cs-CZ', { day: 'numeric', month: 'numeric' });

    const dispatchBaseData = wareDeliveryText ?? (storedOnOwnStore && availableCount > 0 ? 'Skladem' : (variantDeliveryText === '*' && nextDelivery != null ? formatter.format(nextDelivery) : deliveryPeriod ?? 'Vyprodáno'));

    const nextDeliveryDate = Date.parse(nextDelivery);

    // contains date - use it as dispatch date

    if (typeof dispatchBaseData === 'string' && dispatchBaseData.includes('.') && !isNaN(nextDeliveryDate) && nextDeliveryDate > Date.now()) {
      result.date = getSameOrNextWorkingDay(nextDeliveryDate, freeDays, deadlineHour);

      if (serviceProcessingDays) {
        result.date = skipFreeDays(result.date, serviceProcessingDays, freeDays);
      }

      result.text = `Odesíláme ${result.date}`;
    }

    // is number - use it as delivery period to calculate dispatch date

    else if (!isNaN(dispatchBaseData)) {
      const baseDeliveryPeriod = parseInt(dispatchBaseData);

      if (baseDeliveryPeriod === 0) {

        result.date = utils.extractDate(new Date());

        if (serviceProcessingDays) {
          result.date = skipFreeDays(result.date, serviceProcessingDays, freeDays);
        }

        result.text = 'Skladem';
      }
      else if (baseDeliveryPeriod > 0 && baseDeliveryPeriod < 85) {

        const firstProcessingDay = getSameOrNextWorkingDay(new Date(), freeDays, deadlineHour);
        const dispatchDate = skipFreeDays(firstProcessingDay, baseDeliveryPeriod, freeDays);

        result.date = dispatchDate;

        if (serviceProcessingDays) {
          result.date = skipFreeDays(result.date, serviceProcessingDays, freeDays);
        }

        result.text = `Odesíláme ${formatter.format(result.date)}`;
      }
    }

    // is string - use it as custom text

    else {
      result.date = utils.extractDate(new Date());
      result.text = dispatchBaseData;
    }

    //console.log(result, wareDeliveryText, variantDeliveryText, storedOnOwnStore, availableCount, deliveryPeriod, nextDelivery, deadlineHour, freeDays, serviceProcessingDays);

    return result;
  }

  const getDeliveryDate = (dispatchDate: Date, freeDays: Array<Date>, shippingDays: number, skipFreeDaysFn: Function = skipFreeDays): Date => {

    const result = skipFreeDaysFn(dispatchDate, shippingDays, freeDays);

    return result;
  }

  const skipDayOnMissedDeadline = (dateTime: Date, deadlineHour: number): Date => {
    let result = dateTime;

    if (result.getHours() >= deadlineHour) {
      result = utils.addDaysToDate(result, 1);
    }

    result = utils.extractDate(result);

    return result;
  }

  const skipFreeDays = (dateFrom: Date, numberOfWorkingDays: number, freeDays: Array<Date>): Date => {

    let result = getSameOrNextWorkingDay(dateFrom, freeDays);

    for (let i = 0; i < numberOfWorkingDays; i++) {
      result = utils.addDaysToDate(result, 1);
      result = getSameOrNextWorkingDay(result, freeDays);
    }

    return result;
  }

  const getSameOrNextWorkingDay = (dateFrom: Date, freeDays: Array<Date>, deadlineHour: number | undefined = undefined) => {

    let result = dateFrom;

    if (typeof deadlineHour === 'number') {
      result = skipDayOnMissedDeadline(result, deadlineHour);
    }

    while (!isWorkingDay(result, freeDays)) {
      result = utils.addDaysToDate(result, 1);
    }

    result = utils.extractDate(result);

    return result;
  }

  const isWorkingDay = (date: Date, freeDays: Array<Date>) => {
    const result = date.getDay() !== 0 && date.getDay() !== 6 && (!freeDays || !freeDays.some(item => item.toDateString() === date.toDateString()));

    return result;
  }

  const getFreeDays = (): Array<Date> => {
    const result = useState('freeDays').value.map(item => new Date(item));

    return result;
  }

  const loadFreeDays = async () => {
    if (!useState('freeDays').value) {

      useState('freeDays').value = (await useApiFetch(`/api/freeDays`)).data.value.freeDays;
    }
  }

  return {
    isWorkingDay,
    skipFreeDays,
    getSameOrNextWorkingDay,
    getVariantDispatchInfo,
    getVariantDeliveryInfo,
    getDeliveryDate,
    getFreeDays,
    loadFreeDays
  }
}