export const useAnalytics = () => {

  const push = (object) => {
    if (process.client && typeof dataLayer !== 'undefined') {
      dataLayer.push(object);
      console.log(object);
    }
  }

  const pushEvent = (eventName, object) => {
    push(Object.assign({ event: eventName }, Object.assign(object, { _clear: true})));
  }

  const getItemDetails = (ware, variant, quantity = 1) => {

    const appConfig = useAppConfig();
    const liked = useLikedWares();

    const itemDetails = {
      price: ware.price.minPrice.baseWithoutVat,
      quantity: quantity,
      item_id: ware.id,
      item_name: ware.name,
      item_brand: ware.properties?.find(q => q.id === appConfig.parameterIds.vendor)?.values?.[0]?.value,
      item_favorite: liked.isLiked(ware.id).value,
      item_availability: ware.stored ? 'Skladem' : 'Není skladem',
      item_tags: ware.properties?.filter(item => item.id === appConfig.parameterIds.label).flatMap(item => item.values).map(item => item.value).join(',')
    }

    ware.groups?.sort((a, b) => a.priority - b.priority).forEach((item, index) => {
      if (index < 5) {
        const key = 'item_category' + (index > 0 ? `_${(index + 1)}` : '');
        itemDetails[key] = item.group.parents.sort((a, b) => b.level - a.level).flatMap(parentGroup => parentGroup.name).join(' / ');
      }
    });

    if (variant) {

      const variantDetails = {
        item_variant_id: variant.id,
        item_variant_name: variant.variantName,
        item_variant_size: variant.properties?.[0]?.values[0]?.value,
        item_variant_price: variant.prices.baseWithoutVat
      }

      Object.assign(itemDetails, variantDetails);
    }

    return itemDetails;
  }

  return {
    getItemDetails,
    push,
    pushEvent
  };
}